<template>

    <Card class="bg-green-100 card-pago" v-if="pago">
        <template #content>
            <div class="p-3 text-center font-bold">
                <i class="pi pi-check"></i> PAGAMENTO REGISTRADO
            </div>
        </template>
    </Card>

    <Card class="bg-orange-100 card-aguardando" v-else-if="aguardando_resultado && metodo_pagamento != 'pix'">
        <template #content>
            <div class="p-3 text-center font-bold">
                <i class="pi pi-spin pi-cog"></i> Processando Pagamento...
            </div>
        </template>
    </Card>

    <Card class="bg-gray-100 card-escolha" v-else-if="metodo_pagamento == ''">
        <template #content>
            <div class="grid">
                <div class="col-12 text-center font-medium mb-2">
                    Clique na forma de pagamento escolhida:
                </div>
                <div class="col-12 md:col-6 text-center">
                    <Button icon="pi pi-qrcode" label="PIX" class="p-button-secondary p-button-lg" @click="pagar" />
                </div>
                <div class="col-12 md:col-6 text-center">
                    <Button icon="pi pi-credit-card" label="CARTÃO" class="p-button-secondary p-button-lg"
                        @click="metodo_pagamento = 'cartao'" />
                </div>
            </div>
        </template>
    </Card>

    <Card class="bg-gray-100 card-pix" v-else-if="metodo_pagamento == 'pix'">
        <template #content>
            <div class="grid">
                <div class="col-12 md:col-6 text-center">
                    <div class="font-bold mb-2">QR Code:</div>
                    <img :src="'data:image/jpeg;base64,' + pix_qr" alt="" title="QR Code" class="w-full h-auto" />
                </div>
                <div class="col-12 md:col-6 text-center">
                    <div class="font-bold mb-2">Copie e Cole:</div>
                    <Textarea v-model="pix_copia_cola" readonly="true" rows="8" style="width: 100%;"
                        onclick="this.focus();this.select()" />

                    <div>
                        <ProgressSpinner style="width:40px; vertical-align: middle" strokeWidth="4" />
                        <span class="ml-2 font-medium text-pink-700">Aguardando Pagamento</span>
                    </div>

                </div>
            </div>
        </template>
    </Card>

    <Card class="bg-gray-100 card-cartao" v-else-if="metodo_pagamento == 'cartao'">
        <template #content>

            <div class="grid">
                <div class="col-10">
                    <InputMask id="cartao_numero" type="text" v-model="cartao_numero" mask="9999 9999 9999 9999"
                        placeholder="número do cartão"
                        :class="(fieldsError.some(v => v === 'cartao_numero')) ? 'w-full p-invalid' : 'w-full'"
                        @blur="identificaCartao" />
                </div>
                <div class="col-2">
                    <div class="pt-2" v-if="cartao_metodo != null">
                        <img :src="cartao_metodo.secure_thumbnail" :alt="cartao_metodo.name"
                            :title="cartao_metodo.name" />
                    </div>
                </div>
                <div class="col-12 md:col-7">
                    <InputText id="cartao_nome" type="text" v-model="cartao_nome" placeholder="nome do titular"
                        :class="(fieldsError.some(v => v === 'cartao_nome')) ? 'w-full p-invalid' : 'w-full'" />
                </div>
                <div class="col-8 md:col-3">
                    <InputMask id="cartao_validade" v-model="cartao_validade" mask="99/9999" slotChar="mm/aaaa"
                        placeholder="validade"
                        :class="(fieldsError.some(v => v === 'cartao_validade')) ? 'w-full p-invalid' : 'w-full'" />
                </div>
                <div class="col-4 md:col-2">
                    <InputNumber id="cartao_cvv" v-model="cartao_cvv" :max="999" :allowEmpty="true" placeholder="cvv"
                        :class="(fieldsError.some(v => v === 'cartao_cvv')) ? 'p-invalid' : ''" inputClass="w-full" />
                </div>
                <div class="col-12 md:col-7 pb-0">

                    <div v-if="parcelas.length > 0">
                        <Dropdown v-model="parcela" :options="parcelas" optionLabel="recommended_message"
                            :class="(fieldsError.some(v => v === 'parcela')) ? 'w-full p-invalid' : 'w-full'" />
                    </div>

                </div>
                <div class="col-12 md:col-5 pb-0 text-right">

                    <Button icon="pi pi-check" label="Pagar" class="p-button-success" @click="pagar" />

                </div>
            </div>

        </template>
    </Card>

    <div class="loading" v-show="loading">
        <div class="center-spinner">
            <ProgressSpinner />
        </div>
    </div>

    <Dialog :position="($utils.isMobileCheck()) ? 'bottom' : 'center'" v-model:visible="displayCadastro"
        style="width: 530px; max-width: 98%" :modal="true" :closable="true">
        <template #header>
            <h5 class="m-0 text-xl font-medium"><i class="pi pi-user" style="font-size: 1.3rem"></i> Para realizar o
                pagamento, autentique-se</h5>
        </template>
        <CadastroCliente @logado="pagar" />
    </Dialog>

</template>

<script>

import CadastroCliente from "../componentes/CadastroCliente.vue";

export default {
    props: {
        orcamento: Object,
        frete: Object,
        cep: String,
        tipo: String
    },
    data() {
        return {

            loading: false,
            displayCadastro: false,

            fieldsError: [],
            parcelas: [],

            metodo_pagamento: "",
            cartao_numero: "",
            cartao_nome: "",
            cartao_validade: "",
            cartao_cvv: null,
            cartao_metodo: null,
            parcela: null,

            pix_qr: "",
            pix_copia_cola: "",

            pagamento_id: "",
            pago: false,
            aguardando_resultado: false,

            mp_public_key: ""

        };
    },

    mounted() {

        this.$apiv3.get("/mercadopago/loja/conectada").then(res => {

            if(res.data.success === true) {
                this.mp_public_key = res.data.data?.public_key ?? "";
            }

        });

    },

    watch: {
        "$root.user": {
            handler: function (user) {
                if (user)
                    this.cartao_nome = user.nome;
            },
            deep: true,
            immediate: true,
        },
    },

    methods: {

        monitoraResultado() {

            this.aguardando_resultado = true;

            const fn = async () => {

                if(typeof this.pagamento_id != "string" || this.pagamento_id == "")
                    return;

                const response = (await this.$apiv3.get("/mercadopago/status/" + this.pagamento_id)).data;

                if(response.success) {

                    if(response.data == "APROVADO") {

                        this.aguardando_resultado = false;
                        this.pago = true;
                        this.metodo_pagamento = '';

                        this.$toast.add({
                            severity: "success",
                            summary: "Sucesso!",
                            detail: "Pagamento identificado com sucesso!",
                            life: 3000,
                        });

                        this.$root.carregaCarrinho();

                        return;
                    }

                    if(response.data == "RECUSADO") {

                        this.pago = false;
                        this.aguardando_resultado = false;

                        console.log(response);

                        this.$toast.add({
                            severity: "error",
                            summary: "Pagamento NÃO Aprovado!",
                            detail: response.debug.message,
                            life: 5000,
                        });

                        return;
                    }

                    setTimeout(fn, 1500);

                } else {

                    this.pago = false;
                    this.aguardando_resultado = false;

                    console.error(response);

                    this.$toast.add({
                        severity: "error",
                        summary: "Atenção!",
                        detail: "Falha ao identificar o pagamento! Tente novamente.",
                        life: 5000,
                    });

                }

            };

            fn();

        },

        async identificaCartao() {

            const cartao = this.cartao_numero.replace(/[^0-9]/g, "").substr(0, 6);

            if (this.mp_public_key != "" && cartao.length >= 6) {

                const mp = new MercadoPago(this.mp_public_key);

                try {

                    let response = await mp.getPaymentMethods({ bin: cartao });

                    if (response.results.length > 0) {
                        this.cartao_metodo = response.results[0];
                    }

                    response = await mp.getInstallments({
                        amount: this.orcamento.valorTotal.toFixed(2),
                        locale: 'pt-BR',
                        bin: cartao,
                    });

                    if (response.length > 0) {
                        this.parcelas = response[0].payer_costs;
                        this.parcela = this.parcelas[0];
                    }

                } catch (error) {

                    console.log(error);

                }

            }

        },

        async processaPix() {

            if (this.mp_public_key == "") {
                this.$toast.add({
                    severity: "error",
                    summary: "ERRO!",
                    detail: "Empresa não preparada para receber pagamentos (MP)!",
                    life: 5000,
                });
                return;
            }

            this.loading = true;

            let dados = {
                "origem": this.tipo.toLowerCase().trim(),
                "id": this.orcamento.id
            };

            const response = await this.$apiv3.post('/mercadopago/pix', dados);

            if (!response.data.success) {
                console.log(response.data);
            } else {
                this.pagamento_id = response.data.data.id;
                this.pix_qr = response.data.data.qr_code_base64;
                this.pix_copia_cola = response.data.data.qr_code;

                this.metodo_pagamento = "pix";

                this.monitoraResultado();
            }

            this.$toast.add({
                severity: (response.data.success) ? "info" : "error",
                summary: (response.data.success) ? "Atenção!" : "ERRO!",
                detail: response.data.message,
                life: (response.data.success) ? 3000 : 5000,
            });

            this.loading = false;

        },

        async pagar() {

            if(this.$root.user == null) {
                this.displayCadastro = true;
            } else {
                if (this.metodo_pagamento == "cartao")
                    this.processaCartao();
                else
                    this.processaPix();
            }

        },

        async processaCartao() {

            if (this.cartao_numero == "" || this.cartao_nome == "" || this.cartao_validade == "" || this.cartao_cvv == "" || this.cartao_metodo == null || this.parcela == null) {
                this.$toast.add({
                    severity: "error",
                    summary: "ERRO!",
                    detail: "Informe todas as informações do cartão!",
                    life: 5000,
                });
                return;
            }

            if (this.mp_public_key == "") {
                this.$toast.add({
                    severity: "error",
                    summary: "ERRO!",
                    detail: "Empresa não preparada para receber pagamentos (MP)!",
                    life: 5000,
                });
                return;
            }

            this.loading = true;

            const mp = new MercadoPago(this.mp_public_key);

            let response;

            try {

                response = await mp.createCardToken({
                    cardNumber: this.cartao_numero.replace(/[^0-9]/g, ""),
                    cardholderName: this.cartao_nome,
                    cardExpirationMonth: this.cartao_validade.split('/')[0],
                    cardExpirationYear: this.cartao_validade.split('/')[1],
                    securityCode: this.cartao_cvv.toString(),
                    cardholderEmail: this.$root.user.email,
                    identificationType: 'CPF',
                    identificationNumber: this.$root.user.cpf.replace(/[^0-9]/g, ""),
                });

            } catch (error) {

                console.log(error);

                this.$toast.add({
                    severity: "error",
                    summary: "ERRO!",
                    detail: "Erro nas informações do cartão!",
                    life: 5000,
                });

                this.loading = false;

                return;

            }

            let dados = {
                "origem": this.tipo.toLowerCase().trim(),
                "cartao_token": response.id,
                "metodo_pagamento": this.cartao_metodo.id,
                "parcelas": this.parcela.installments,
                "id": this.orcamento.id
            };

            response = await this.$apiv3.post('/mercadopago/cartao', dados);

            if (!response.data.success) {
                console.log(response.data);
            } else {
                this.pagamento_id = response.data.data.id;
                this.monitoraResultado();
            }

            this.$toast.add({
                severity: (response.data.success) ? "info" : "error",
                summary: (response.data.success) ? "Atenção!" : "ERRO!",
                detail: response.data.message,
                life: (response.data.success) ? 3000 : 5000,
            });

            this.loading = false;

        },

    },
    components: {
        CadastroCliente
    }

};
</script>

<style lang="scss" scoped>

:deep(.card-cartao .p-card-content),
:deep(.card-pix .p-card-content) {
    padding: 0;
}

</style>