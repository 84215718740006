// #region GLOBALS //

const version = "3.0.15";
// A VARIAVEL isDev pra false deixa ele acessando a api apenas online...
const isDev = window.location.hostname == "localhost";

// #endregion GLOBALS //

import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import { Notifier } from '@airbrake/browser'

import PrimeVue from 'primevue/config';

import AutoComplete from 'primevue/autocomplete';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import BadgeDirective from 'primevue/badgedirective';
import Button from 'primevue/button';
import Breadcrumb from 'primevue/breadcrumb';
import Calendar from 'primevue/calendar';
import Card from 'primevue/card';
import Carousel from 'primevue/carousel';
import Chart from 'primevue/chart';
import Checkbox from 'primevue/checkbox';
import Chips from 'primevue/chips';
import ColorPicker from 'primevue/colorpicker';
import Column from 'primevue/column';
import ConfirmationService from 'primevue/confirmationservice';
import ConfirmDialog from 'primevue/confirmdialog';
import ConfirmPopup from 'primevue/confirmpopup';
import ContextMenu from 'primevue/contextmenu';
import DataTable from 'primevue/datatable';
import DataView from 'primevue/dataview';
import DataViewLayoutOptions from 'primevue/dataviewlayoutoptions';
import Dialog from 'primevue/dialog';
import Dropdown from 'primevue/dropdown';
import Fieldset from 'primevue/fieldset';
import FileUpload from 'primevue/fileupload';
import InlineMessage from 'primevue/inlinemessage';
import Inplace from 'primevue/inplace';
import InputMask from 'primevue/inputmask';
import InputNumber from 'primevue/inputnumber';
import InputSwitch from 'primevue/inputswitch';
import InputText from 'primevue/inputtext';
import Galleria from 'primevue/galleria';
import Image from 'primevue/image';
import Listbox from 'primevue/listbox';
import MegaMenu from 'primevue/megamenu';
import Menu from 'primevue/menu';
import Menubar from 'primevue/menubar';
import Message from 'primevue/message';
import MultiSelect from 'primevue/multiselect';
import OrderList from 'primevue/orderlist';
import OrganizationChart from 'primevue/organizationchart';
import OverlayPanel from 'primevue/overlaypanel';
import Paginator from 'primevue/paginator';
import Panel from 'primevue/panel';
import PanelMenu from 'primevue/panelmenu';
import Password from 'primevue/password';
import PickList from 'primevue/picklist';
import ProgressBar from 'primevue/progressbar';
import ProgressSpinner from 'primevue/progressspinner';
import Rating from 'primevue/rating';
import RadioButton from 'primevue/radiobutton';
import Ripple from 'primevue/ripple';
import SelectButton from 'primevue/selectbutton';
import Skeleton from 'primevue/skeleton';
import Slider from 'primevue/slider';
import Sidebar from 'primevue/sidebar';
import SplitButton from 'primevue/splitbutton';
import Steps from 'primevue/steps';
import TabMenu from 'primevue/tabmenu';
import TieredMenu from 'primevue/tieredmenu';
import Textarea from 'primevue/textarea';
import Toast from 'primevue/toast';
import ToastService from 'primevue/toastservice';
import Toolbar from 'primevue/toolbar';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import Tooltip from 'primevue/tooltip';
import ToggleButton from 'primevue/togglebutton';
import Tree from 'primevue/tree';
import TreeTable from 'primevue/treetable';
import TriStateCheckbox from 'primevue/tristatecheckbox';
import VueScrollTo from 'vue-scrollto';

/////////// Personalizados ///////////////

import ProdutoCompleto from './componentes/ProdutoCompleto.vue';
import ProdutoResumido from './componentes/ProdutoResumido.vue';
import Status from './componentes/Status.vue';
import Carrocel from './componentes/Carrocel.vue';
import AdicionarCarrinho from "./componentes/AdicionarCarrinho.vue";
import DetalhesProduto from "./componentes/DetalhesProduto.vue";
import Stories from "vue3-insta-stories";
import VuePlayerVideo from 'vue3-player-video';
import StorysInsta from './componentes/StorysInsta.vue';
import Banner from './componentes/Banner.vue';
import PagamentoMP from "./componentes/PagamentoMP.vue";

//////////////////////////////////////////

import 'primevue/resources/themes/saga-blue/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';

import Utils from './utils';
import API from './servicos/api';

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import FingerprintJS from '@fingerprintjs/fingerprintjs';

let api, apiv3;
if(isDev) {
    api = new API("https://us-central1-npc-store-2.cloudfunctions.net/apiv2");
    // api = new API("http://localhost:5002/npc-store-2/us-central1/apiv2");
    apiv3 = new API("http://localhost:5003");
} else {
    api = new API("https://us-central1-npc-store-2.cloudfunctions.net/apiv2");
    apiv3 = new API("https://api.npc.software");
}

const firebaseConfig = {
    apiKey: "AIzaSyC7C3MFIxmr1K2SaehqKZ9b67IeCrYRMTc",
    authDomain: "npc-store.firebaseapp.com",
    databaseURL: "https://npc-store.firebaseio.com",
    projectId: "npc-store",
    storageBucket: "npc-store.appspot.com",
    messagingSenderId: "935518912838",
    appId: "1:935518912838:web:902ddac471e938e13b6547",
    measurementId: "G-K2RBQ365GS"
};

getAnalytics(initializeApp(firebaseConfig));

var fbq = function() {}

//////////////////////////////////////////

import Hotjar from '@hotjar/browser';

if(window.location.hostname != "localhost" && window.location.hostname != "127.0.0.1") { // TODO Otimizar Inicialização

    const siteId = 3501236;
    const hotjarVersion = 6;

    Hotjar.init(siteId, hotjarVersion);

}

//////////////////////////////////////////

const auth = getAuth();

router.beforeEach((to, from, next) => {
    
    fbq("track","ViewContent");

    window.scrollTo(0, 0);

	const currentUser = auth.currentUser;
	const requiresAuth = to.matched.some(record => record.meta.requiresAuth);

	if (requiresAuth && currentUser === null) next('login');
	else next();
});

const fpPromise = FingerprintJS.load();

let app = '';

fpPromise.then(fp => fp.get()).then(fpResult => {

    api.setFpid(fpResult.visitorId);
    apiv3.setFpid(fpResult.visitorId);

    if (!app) {

        app = createApp(App);

        // #region AirBrake - Inicialização //

        let envBrake = "development";
        if(window.location.hostname != "localhost" && window.location.hostname != "127.0.0.1") {
            envBrake = "production";
        }

        if(envBrake == "production") {

            const airbrake = new Notifier({
                environment: envBrake,
                projectId: 509796,
                projectKey: '7945a22f119fec443fea7dbc8b302875'
            });

            app.config.errorHandler = function (err, vm, info) {
                airbrake.notify({
                    error: err,
                    params: {info: info}
                });
            }

        }

        // #endregion AirBrake //

        app.config.globalProperties.$version = version;
        app.config.globalProperties.$utils = Utils;
        app.config.globalProperties.$fpid = fpResult.visitorId;
        app.config.globalProperties.$api = api;
        app.config.globalProperties.$apiv3 = apiv3;
        app.config.globalProperties.$window = window;
        app.config.globalProperties.$fbq = fbq;

        app.use(router);
        app.use(PrimeVue, {
            ripple: true,
            locale: {
                accept: "Sim",
                reject: "Não",
                choose: "Selecionar",
                upload: "Upload",
                cancel: "Cancelar",
                dayNames: ["Domingo", "Segunda", "Terça", "Quarta", "Quinta", "Sexta", "Sábado"],
                dayNamesShort: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"],
                dayNamesMin: ["D","S","T","Q","Q","S","S"],
                monthNames: ["Janeiro","Fevereiro","Março","Abril","Maio","Junho","Julho","Agosto","Setembro","Outubro","Novembro","Dezembro"],
                monthNamesShort: ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun","Jul", "Ago", "Set", "Out", "Nov", "Dez"],
                today: "Hoje",
                weekHeader: "Sm",
                firstDayOfWeek: 0,
                dateFormat: "dd/mm/yy",
                weak: "Fraco",
                medium: "Médio",
                strong: "Forte",
                passwordPrompt: "Informe a Senha"
            },
        });

        app.use(ConfirmationService);
        app.use(ToastService);

        app.use(VueScrollTo);

        app.directive('tooltip', Tooltip);
        app.directive('ripple', Ripple);
        app.directive('badge', BadgeDirective);

        app.component('Accordion', Accordion);
        app.component('AccordionTab', AccordionTab);
        app.component('AutoComplete', AutoComplete);
        app.component('Breadcrumb', Breadcrumb);
        app.component('Button', Button);
        app.component('Calendar', Calendar);
        app.component('Card', Card);
        app.component('Carousel', Carousel);
        app.component('Chart', Chart);
        app.component('Checkbox', Checkbox);
        app.component('Chips', Chips);
        app.component('ColorPicker', ColorPicker);
        app.component('Column', Column);
        app.component('ConfirmPopup', ConfirmPopup);
        app.component('ConfirmDialog', ConfirmDialog);
        app.component('ContextMenu', ContextMenu);
        app.component('DataTable', DataTable);
        app.component('DataView', DataView);
        app.component('DataViewLayoutOptions', DataViewLayoutOptions);
        app.component('Dialog', Dialog);
        app.component('Dropdown', Dropdown);
        app.component('Fieldset', Fieldset);
        app.component('FileUpload', FileUpload);
        app.component('InlineMessage', InlineMessage);
        app.component('Inplace', Inplace);
        app.component('InputMask', InputMask);
        app.component('InputNumber', InputNumber);
        app.component('InputSwitch', InputSwitch);
        app.component('InputText', InputText);
        app.component('Galleria', Galleria);
        app.component('Image', Image);
        app.component('Listbox', Listbox);
        app.component('MegaMenu', MegaMenu);
        app.component('Menu', Menu);
        app.component('Menubar', Menubar);
        app.component('Message', Message);
        app.component('MultiSelect', MultiSelect);
        app.component('OrderList', OrderList);
        app.component('OrganizationChart', OrganizationChart);
        app.component('OverlayPanel', OverlayPanel);
        app.component('Paginator', Paginator);
        app.component('Panel', Panel);
        app.component('PanelMenu', PanelMenu);
        app.component('Password', Password);
        app.component('PickList', PickList);
        app.component('ProgressBar', ProgressBar);
        app.component('ProgressSpinner', ProgressSpinner);
        app.component('RadioButton', RadioButton);
        app.component('Rating', Rating);
        app.component('SelectButton', SelectButton);
        app.component('Skeleton', Skeleton);
        app.component('Slider', Slider);
        app.component('Sidebar', Sidebar);
        app.component('SplitButton', SplitButton);
        app.component('Steps', Steps);
        app.component('TabMenu', TabMenu);
        app.component('TabView', TabView);
        app.component('TabPanel', TabPanel);
        app.component('Textarea', Textarea);
        app.component('TieredMenu', TieredMenu);
        app.component('Toast', Toast);
        app.component('Toolbar', Toolbar);
        app.component('ToggleButton', ToggleButton);
        app.component('Tree', Tree);
        app.component('TreeTable', TreeTable);
        app.component('TriStateCheckbox', TriStateCheckbox);

        /////////// Personalizados ///////////////

        app.component('ProdutoCompleto', ProdutoCompleto);
        app.component('ProdutoResumido', ProdutoResumido);
        app.component('Status', Status);
        app.component('Carrocel', Carrocel);
        app.component('AdicionarCarrinho', AdicionarCarrinho);
        app.component('DetalhesProduto', DetalhesProduto);
        app.component('Stories', Stories);
        app.component('VuePlayerVideo', VuePlayerVideo);
        app.component('StorysInsta', StorysInsta);
        app.component('Banner', Banner);
        app.component('PagamentoMP', PagamentoMP);

        //////////////////////////////////////////

        app.mount('#app');

    }

});
